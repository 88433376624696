<style scoped lang="scss">
.simple-snotify {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Ajouter Zone</h4>
        <p class="card-description"></p>
        <form
          class="forms-sample"
          @submit.prevent="addzone()"
          novalidate="true"
        >
          <div v-if="errors.length">
            <ul>
              <li class="invalid-feedback" v-for="error in errors" :key="error">
                - {{ error }}
              </li>
            </ul>
          </div>

          <div class="form-group">
            <label for="exampleInputName1">Intitule<span class="obligatoire">*</span></label>
            <input
              v-model="intitule"
              type="text"
              class="form-control"
              id="intitule"
              placeholder="intitule"
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail3">Lattitude<span class="obligatoire">*</span></label>
            <input
              v-model="lattitude"
              type="number"
              class="form-control"
              id="lattitude"
              placeholder="lattitude"
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword4">Longitude<span class="obligatoire">*</span></label>
            <input
              v-model="longitude"
              type="number"
              class="form-control"
              id="longitude"
              placeholder="longitude"
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleSelectGender">Gouvernerat<span class="obligatoire">*</span></label>
            <select class="form-control" id="gouvernerat" v-model="gouvernerat" required>
              <option>Ariana</option>
              <option>Beja</option>
              <option>Ben Arous</option>
              <option>Bizerte</option>
              <option>Gabes</option>
              <option>Gafsa</option>
              <option>Jendouba</option>
              <option>Kairouan</option>
              <option>Kasserine</option>
              <option>Kebili</option>
              <option>Kef</option>
              <option>Mahdia</option>
              <option>Manouba</option>
              <option>Mednine</option>
              <option>Monastir</option>
              <option>Nabeul</option>
              <option>Sfax</option>
              <option>Sidi-Bouzid</option>
              <option>Siliana</option>
              <option>Sousse</option>
              <option>Tataouine</option>
              <option>Tozeur</option>
              <option>Tunis</option>
              <option>Zaghouane</option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleSelectGender">Déligation <span class="obligatoire">*</span></label>
            <select class="form-control" id="delegation" v-model="delegation" required>
              <option>Sfax Ville</option>
              <option>Sfax Ouest</option>
              <option>Ariana</option>
              <option>Hraïria</option>
              <option>M'saken</option>
              <option>Kairouan</option>
              <option>El Mourouj</option>
              <option>Monastir</option>
              <option>El Kabaria</option>
              <option>Kairouan Sud</option>
              <option>Sidi Hassine</option>
              <option>Ettadhamen</option>
              <option>La Marsa</option>
              <option>Douar Hicher</option>
              <option>Moknine</option>
              <option>Bizerte</option>
              <option>Zarzis</option>
              <option>Sakiet</option>
              <option>Mahdia</option>
              <option>Bou Hajla</option>
              <option>Le Bardo</option>
              <option>Sbeïtla</option>
              <option>Jendouba</option>
              <option>Ghardima</option>
              <option>Béja Nord</option>
              <option>Sbikha</option>
              <option>Sousse</option>
              <option>Djerba</option>
              <option>Sousse</option>
              <option>El Hamma</option>
              <option>Gabès Sud</option>
              <option>Regueb</option>
              <option>Oued uba</option>
              <option>Kassererine</option>
              <option>Le Kram</option>
              <option>Fouchana</option>
              <option>Grombalia</option>
              <option>Jemmal</option>
              <option>Tataouinene</option>
              <option>Mnihla</option>
              <option>Mornag</option>
              <option>Kélibia</option>
              <option>Nefza</option>
              <option>Ras Jebal</option>
              <option>Kalâa</option>
              <option>Djerba</option>
              <option>Bir Ali</option>
              <option>Ksour Essef</option>
              <option>Sidi Bouziuzid</option>
              <option>Médenine</option>
              <option>Mateur</option>
              <option>Gabès</option>
              <option>Mohamedia</option>
              <option>Essouas</option>
              <option>Sousse</option>
              <option>Fériana</option>
              <option>Thyna</option>
              <option>Bizerte</option>
              <option>Jendouba</option>
              <option>Jebiniana</option>
              <option>Haffouz</option>
              <option>Enfida</option>
              <option>Balta-Bdouba</option>
              <option>Sejnane</option>
              <option>Sbiba</option>
              <option>Solimane</option>
              <option>Foussana</option>
              <option>El Jem</option>
              <option>Tebourba</option>
              <option>El Omrane</option>
              <option>Kef Est</option>
              <option>Aïn Draham</option>
              <option>Djedeida</option>
              <option>Ksar Hellal</option>
              <option>Tozeur</option>
              <option>Menzel Jemil</option>
              <option>Dar Chaâbane</option>
              <option>El Haouaria</option>
              <option>Medjez el-beb</option>
              <option>Métlaoui</option>
              <option>Béja Sud</option>
              <option>Nasrallah</option>
              <option>Jilma</option>
              <option>Cité El Khadra</option>
              <option>Oueslatia</option>
              <option>Séjoumi</option>
              <option>Agareb</option>
              <option>Sidi Alouane</option>
              <option>Joumine</option>
              <option>Mornaguia</option>
              <option>Hammam Sousse</option>
              <option>Sened</option>
              <option>Menzel Chaker</option>
              <option>Bir El Hafey</option>
              <option>Béni khalléd</option>
              <option>Chebika</option>
              <option>Tataouinne</option>
              <option>El Ouardia</option>
              <option>Menzel Bouzelfa</option>
              <option>Bab Souika</option>
              <option>Testour</option>
              <option>Ben Arous</option>
              <option>El Ksar</option>
              <option>Ezzahra</option>
              <option>El Alâa</option>
              <option>Moularès</option>
              <option>Téboulba</option>
              <option>Makthar</option>
              <option>Dahmani</option>
              <option>Mahrés</option>
              <option>Tajerouine</option>
              <option>Rouhia</option>
              <option>Kef Ouest</option>
              <option>Sidi El Bechir</option>
              <option>Sousse Médina</option>
              <option>Skhira</option>
              <option>Bou Merdes</option>
              <option>Kébili Sud</option>
              <option>Siliana Sud</option>
              <option>El Amra</option>
              <option>Ksibet el-Médiouni</option>
              <option>Beni Khedache</option>
              <option>Chorbane</option>
              <option>Nadhour</option>
              <option>La Goulette</option>
              <option>Nebeur</option>
              <option>Kébili Nord</option>
              <option>Majel Bel Abbès</option>
              <option>Sahline</option>
              <option>El Krib</option>
              <option>Bir Mcherga</option>
              <option>Kasserine Sud</option>
              <option>Bou Arada</option>
              <option>Zriba</option>
              <option>Carthage</option>
              <option>Nefta</option>
              <option>Sakiet Sidi Youssef</option>
              <option>Ezzouhour</option>
              <option>Takelsa</option>
              <option>El Guettar</option>
              <option>Ouled Haffouz</option>
              <option>Melloulèche</option>
              <option>Tinja</option>
              <option>Sidi Thabet</option>
              <option>Souk Jedid</option>
              <option>Oued Meliz</option>
              <option>Ouerdanine</option>

              <option>El Ayoun</option>
              <option>Cebbala Ouled Asker</option>
              <option>Ghomrassen</option>
              <option>Utique</option>
              <option>Gaâfour</option>
              <option>Kesra</option>
              <option>Sidi Bou Ali</option>
              <option>El Batan</option>

              <option>Douz Sud</option>
              <option>El Ksour</option>
              <option>Hassi El Ferid</option>
              <option>Kalaat Senan</option>
              <option>Goubellat</option>
              <option>Faouar</option>
              <option>Borj El Amri</option>
              <option>Sidi Bou Rouis</option>

              <option>Belkhir</option>
              <option>Mdhilla</option>
              <option>Graïba</option>
              <option>Kerkennah</option>
              <option>Hammam Ghezèze</option>
              <option>Smâr</option>
              <option>Bargou</option>
              <option>Bekalta</option>
              <option>Jérissa</option>
              <option>Jedelienne</option>
              <option>Beni Hassen</option>
              <option>Saouaf</option>
              <option>Kondar</option>
              <option>Sidi El Hani</option>
              <option>Menzel El Habib</option>
              <option>Jérissa</option>
              <option>Hebira</option>
              <option>Thibar</option>
              <option>Remada</option>
              <option>El Aroussa</option>
              <option>Gafsa Nord</option>
              <option>Bir Lahmar</option>
              <option>Haïdra</option>
              <option>Sidi Aïch</option>
              <option>Hergla</option>
              <option>Kalâat Khasba</option>
              <option>Tameghza</option>
              <option>Matmata</option>
              <option>Hazoua</option>
              <option>Dehiba</option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleSelectGender">Municipalité<span class="obligatoire">*</span></label>
            <select
              class="form-control"
              id="municipalite"
              v-model="municipalite"
              required
            >
              <option>Ariana</option>
              <option>Béja</option>
              <option>Ben Arous</option>
              <option>Bizerte</option>
              <option>Gabès</option>
              <option>Gafsa</option>
              <option>Jendouba</option>
              <option>Kairouan</option>
              <option>Kasserine</option>
              <option>Kébili</option>
              <option>Le Kef</option>
              <option>Mahdia</option>
              <option>La Manouba</option>
              <option>Médenine</option>
              <option>Monastir</option>
              <option>Nabeul</option>
              <option>Sfax</option>
              <option>Sidi Bouzid</option>
              <option>Siliana</option>
              <option>Sousse</option>
              <option>Tataouine</option>
              <option>Tozeur</option>
              <option>Tunis</option>
              <option>Zaghouane</option>
            </select>
          </div>

          <button type="submit" class="btn btn-gradient-primary mr-2">
            Ajouter
          </button>
          <router-link :to="{ name: 'zones' }"
            ><button class="btn btn-light">Annuler</button></router-link
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/axios';
import axios from 'axios';

export default {
  name: "addzone",
  data() {
    return {
      errors: [],

      lattitude: "",
      longitude: "",
      intitule: "",
      gouvernerat: "",
      delegation: "",
      municipalite: "",
    };
  },
  methods: {
    addzone() {
      if (
        this.lattitude &&
        this.longitude &&
        this.intitule &&
        this.gouvernerat &&
        this.delegation &&
        this.municipalite
      ) {
        let zone = {
          lattitude: this.lattitude,
          longitude: this.longitude,
          intitule: this.intitule,
          gouvernerat: this.gouvernerat,
          delegation: this.delegation,
          municipalite: this.municipalite,
        };
        axios.post("zones/addzone", zone).then((response) => {
          Toast.fire({
            icon: "success",
            title: "zone ajouté",
          });
          this.$router.push("/getzones");
        });
      }

      this.errors = [];

      if (!this.lattitude) {
        this.errors.push("lattitude required.");
      }
      if (!this.longitude) {
        this.errors.push("longitude required.");
      }

      if (!this.intitule) {
        this.errors.push("intitule required.");
      }
      if (!this.gouvernerat) {
        this.errors.push("gouvernerat required.");
      }
      if (!this.delegation) {
        this.errors.push("delegation required.");
      }
      if (!this.municipalite) {
        this.errors.push("municipalite required.");
      }
    },
  },
};
</script>
